import { ArticleCardType } from "../types/article_card_type"

export const getCategoryName = (category: string) => {
  switch (category) {
    case "learn":
      return "aprenda"
    case "analysis":
      return "analises"
    case "market":
      return "mercado"
    default:
      return category
  }
}

export const getCategoryKey = (category: string) => {
  switch (category) {
    case "aprenda":
      return "learn"
    case "analises":
      return "analysis"
    case "mercado":
      return "market"
    default:
      return category
  }
}
