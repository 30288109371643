import { graphql, useStaticQuery } from "gatsby"
import {
  ArticleCardType,
  articlesCardsListFromMap,
} from "../types/article_card_type"

const RequestAllArticles = () => {
  let articlesList: ArticleCardType[] = []

  const allArticles = useStaticQuery(
    graphql`
      query AllArticles {
        allStrapiArticle(sort: { publishedAt: DESC }, filter: { category: { ne: "market" } }) {
          edges {
            node {
              locale
              ...ArticleCardFields
            }
          }
        }
      }
    `
  )

  articlesList = articlesCardsListFromMap(allArticles.allStrapiArticle.edges)

  return articlesList
}

export default RequestAllArticles
