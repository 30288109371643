import React, { useEffect, useState } from "react"
import { ArticleCardType } from "../types/article_card_type"
import RequestAllArticles from "../graphql/request_all_articles"
import ArticlesGridView from "../components/articles_grid_view"
import RoundedButton from "../components/rounded_button"
import { SEO } from "../components/seo"
import { useTranslation } from "react-i18next"
import BreadcrumbComponent from "../components/breadcrumb"
import { getCategoryKey } from "../utils/functions"
import Layout from "../components/blog/blog-layout"
import ImageMetaTag from "../images/image-meta-tag.webp"

const SearchPage = ({ pageContext }) => {
  let filteredArticlesList: ArticleCardType[] = []
  const { i18n, t } = useTranslation()

  // Array of all news articles
  var allArticles = RequestAllArticles().filter(article => {
    return article.locale == i18n.language
  })

  // State for the list
  const [list, setList] = useState([...allArticles.slice(0, 20)])
  const [filteredList, setFilteredList] = useState([...filteredArticlesList])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allArticles.length > 20)

  const [categoryFilter, setCategoryFilter] = useState("")

  const [message, setMessage] = useState("")

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  if (categoryFilter != "") {
    filteredArticlesList = allArticles.filter(article => {
      return (
        article.category == categoryFilter && article.locale == i18n.language
      )
    })
  }

  useEffect(() => {
    if (message != "") {
      filteredArticlesList = allArticles.filter(article => {
        return (
          article.title.toLowerCase().match(message.toLowerCase()) &&
          article.locale == i18n.language
        )
      })
    } else {
      allArticles = allArticles.filter(article => {
        return article.locale == i18n.language
      })
    }
  }, [message, allArticles])

  useEffect(() => {
    if (categoryFilter != "" || message != "") {
      setFilteredList([...filteredArticlesList.slice(0, 20)])
    } else {
      setHasMore(true)
    }
  }, [categoryFilter, i18n.language, message])

  useEffect(() => {
    var category = getCategoryKey(pageContext.category)
    setCategoryFilter(category)
  }, [])

  useEffect(() => {
    allArticles = allArticles.filter(article => {
      return article.locale == i18n.language
    })
  }, [i18n.language])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      let currentLength: number
      let nextResults: ArticleCardType[]
      let isMore: boolean

      if (categoryFilter != "" || message != "") {
        currentLength = filteredList.length
        isMore = currentLength < filteredArticlesList.length
        nextResults = isMore
          ? filteredArticlesList.slice(currentLength, currentLength + 20)
          : []
        setFilteredList([...filteredList, ...nextResults])
      } else {
        currentLength = list.length
        isMore = currentLength < allArticles.length
        nextResults = isMore
          ? allArticles.slice(currentLength, currentLength + 20)
          : []
        setList([...list, ...nextResults])
      }

      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    let isMore: boolean

    if (categoryFilter != "" || message != "") {
      isMore = filteredList.length < filteredArticlesList.length
    } else {
      isMore = list.length < allArticles.length
    }

    setHasMore(isMore)
  }, [list, filteredList]) //eslint-disable-line

  const handleInputChange = event => {
    setMessage(event.target.value)
    setCategoryFilter("")
  }

  return (
    <Layout currentPage={`/${pageContext.category}`}>
      <BreadcrumbComponent
        children={[
          { pageName: t("homePage"), path: "/blog/" },
          { pageName: t(getCategoryKey(pageContext.category)) },
        ]}
      />
      <main className="flex flex-col items-center mx-auto px-5 max-w-8xl w-full grow">
        <div className="grid grid-cols-12 sm:flex-wrap md:flex-row w-full max-lg:gap-y-3 sm:gap-x-3 justify-between my-10">
          <input
            className="col-span-12 md:col-span-6 rounded-full border font-poppins border-black px-8 py-3 bg-transparent text-black text-lg font-normal bg-arrow-right bg-27 pr-16 bg-no-repeat bg-right-offset-1 active:border-purple-primary focus:bg-arrow-right-purple focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-purple-primary placeholder:text-black placeholder:text-lg placeholder:font-normal"
            onChange={handleInputChange}
            value={message}
            placeholder={t("enterYourSearchHere")}
          />
        </div>

        {categoryFilter != "" || message != "" ? (
          <ArticlesGridView
            articles={filteredList}
            cardTitleSize="text-xl"
            cardClasses="col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-3"
            className="grid-cols-1 md:grid-cols-8 lg:grid-cols-12 mt-10"
          />
        ) : (
          <ArticlesGridView
            articles={list}
            cardTitleSize="text-xl"
            cardClasses="col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-3"
            className="grid-cols-1 md:grid-cols-8 lg:grid-cols-12 mt-10"
          />
        )}

        {hasMore ? (
          <RoundedButton onClick={handleLoadMore}>
            {t("loadMore")}
          </RoundedButton>
        ) : (
          <p className="mt-14 mb-8 text-lg text-gray-400">
            {t("noMoreResults")}
          </p>
        )}

        <hr className="mb-24 w-full border-lines" />
      </main>
    </Layout>
  )
}

export const Head = ({ pageContext }) => {
  const { t } = useTranslation()
  let currentDate = new Date().toISOString().split("T")[0]

  return (
    <SEO 
      title={`DgFresh - ${t(getCategoryKey(pageContext.category))}`}
      description={pageContext.description}
      pathname={pageContext.slug}
      thumbnail={ImageMetaTag}
      pageType="CollectionPage"
    />
  )
}

export default SearchPage
