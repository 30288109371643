import { Breadcrumbs } from "@material-tailwind/react"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import React from "react"

export type PageProps = {
  pageName: string
  path?: string | undefined
}

type BreadcrumbProps = {
  children: PageProps[]
}

const BreadcrumbComponent = ({ children }: BreadcrumbProps) => {
  return (
    <div className="h-28 bg-purple-primary mb-8 w-full  items-center flex mt-20">
      <Breadcrumbs
        className="max-w-8xl mx-auto bg-transparent "
        fullWidth={true}
        separator={
          <ChevronRightIcon
            className="h-5 w-5 text-white opacity-40"
            strokeWidth={2.5}
          />
        }
      >
        {children.map((page, index) => {
          return (
            <a
              href={page.path ?? "#"}
              className={`text-base lg:text-xl font-inter font-medium text-white ease-in-out opacity-40 hover:!opacity-100 ${
                index == children.length - 1 && "!opacity-100 block truncate"
              }`}
            >
              {page.pageName}
            </a>
          )
        })}
      </Breadcrumbs>
    </div>
  )
}

export default BreadcrumbComponent
